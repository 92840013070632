export function hexagon(pen, ctx) {
  const path = !ctx ? new Path2D() : ctx;
  const {x, y, width, height} = pen.calculative.worldRect;
  path.moveTo(x + width / 4, y);
  path.lineTo(x + (width * 3) / 4, y);
  path.lineTo(x + width, y + height / 2);
  path.lineTo(x + (width * 3) / 4, y + height);
  path.lineTo(x + (width * 1) / 4, y + height);
  path.lineTo(x, y + height / 2);
  path.lineTo(x + width / 4, y);

  path.closePath();
  if (path instanceof Path2D) return path;
}
