import {rgba} from '../utils';
import {createOffscreen} from './offscreen';

export class CanvasImage {
  canvas = document.createElement('canvas');
  /**
   * 非图片的绘制
   * isBottom true 指背景颜色，背景网格
   * isBottom false 指 标尺
   */
  otherOffscreen = createOffscreen(); // 非图片的
  offscreen = createOffscreen();
  animateOffsScreen = createOffscreen();

  constructor(
    parentElement,
    store,
    isBottom
  ) {

    this.parentElement = parentElement
    this.store = store
    this.isBottom = isBottom
    parentElement.appendChild(this.canvas);
    this.canvas.style.backgroundRepeat = 'no-repeat';
    this.canvas.style.backgroundSize = '100% 100%';
    this.canvas.style.position = 'absolute';
    this.canvas.style.top = '0';
    this.canvas.style.left = '0';
    this.canvas.setAttribute('name', 'image' + (isBottom ? '-bottom' : ''))
  }

  resize(w, h) {
    this.canvas.style.width = w + 'px';
    this.canvas.style.height = h + 'px';

    w = (w * this.store.dpiRatio) | 0;
    h = (h * this.store.dpiRatio) | 0;

    this.canvas.width = w;
    this.canvas.height = h;

    this.otherOffscreen.width = w;
    this.otherOffscreen.height = h;

    this.offscreen.width = w;
    this.offscreen.height = h;

    this.animateOffsScreen.width = w;
    this.animateOffsScreen.height = h;

    this.otherOffscreen.getContext('2d').scale(this.store.dpiRatio, this.store.dpiRatio);
    this.otherOffscreen.getContext('2d').textBaseline = 'middle';

    this.offscreen.getContext('2d').scale(this.store.dpiRatio, this.store.dpiRatio);
    this.offscreen.getContext('2d').textBaseline = 'middle';

    this.animateOffsScreen.getContext('2d').scale(this.store.dpiRatio, this.store.dpiRatio);
    this.animateOffsScreen.getContext('2d').textBaseline = 'middle';

    this.init();
  }

  init() {
    this.offscreen.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.animateOffsScreen.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.store.patchFlagsBackground = true;
    this.store.patchFlagsTop = true;
  }

  clear() {
    this.otherOffscreen.getContext('2d').clearRect(0, 0, this.otherOffscreen.width, this.otherOffscreen.height);
    this.offscreen.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.animateOffsScreen.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.canvas.getContext('2d').clearRect(0, 0, this.canvas.width, this.canvas.height);
  }

  render() {
    const patchFlagsBackground = this.store.patchFlagsBackground;
    if (patchFlagsBackground && this.isBottom) {
      const ctx = this.otherOffscreen.getContext('2d');
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      const background = this.store.data.background || this.store.options.background;
      if (background) {
        ctx.save();
        ctx.fillStyle = background;
        ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
        ctx.restore();
      }
      this.renderGrid(ctx);
    }

    const patchFlagsTop = this.store.patchFlagsTop;
    if (patchFlagsTop && !this.isBottom) {
      const ctx = this.otherOffscreen.getContext('2d');
      ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
      this.renderRule(ctx);
    }

    if (
      (patchFlagsBackground && this.isBottom) ||
      (patchFlagsTop && !this.isBottom)
    ) {
      const ctxCanvas = this.canvas.getContext('2d');
      ctxCanvas.clearRect(0, 0, this.canvas.width, this.canvas.height);
      if (this.isBottom) {
        ctxCanvas.drawImage(this.otherOffscreen, 0, 0, this.canvas.width, this.canvas.height);
        this.store.patchFlagsBackground = false;
      }
      ctxCanvas.drawImage(this.offscreen, 0, 0, this.canvas.width, this.canvas.height);
      ctxCanvas.drawImage(this.animateOffsScreen, 0, 0, this.canvas.width, this.canvas.height);
      if (!this.isBottom) {
        ctxCanvas.drawImage(this.otherOffscreen, 0, 0, this.canvas.width, this.canvas.height);
        this.store.patchFlagsTop = false;
      }
    }
  }

  renderGrid(ctx) {
    const {data, options} = this.store;
    const {grid, gridRotate, gridColor, gridSize, scale} = data;
    if (!(grid ?? options.grid)) {
      // grid false 时不绘制, undefined 时看 options.grid
      return;
    }
    ctx.save();
    const {width, height} = this.canvas;
    if (gridRotate) {
      ctx.translate(width / 2, height / 2);
      ctx.rotate((gridRotate * Math.PI) / 180);
      ctx.translate(-width / 2, -height / 2);
    }
    ctx.lineWidth = 1;
    ctx.strokeStyle = gridColor || options.gridColor;
    ctx.beginPath();
    const size = (gridSize || options.gridSize) * scale;
    const longSide = Math.max(width, height);
    const count = Math.ceil(longSide / size);
    for (let i = -size * count; i < longSide * 2; i += size) {
      ctx.moveTo(i, -longSide);
      ctx.lineTo(i, longSide * 2);
    }
    for (let i = -size * count; i < longSide * 2; i += size) {
      ctx.moveTo(-longSide, i);
      ctx.lineTo(longSide * 2, i);
    }
    ctx.stroke();
    ctx.restore();
  }

  renderRule(ctx) {
    const {data, options} = this.store;
    const {rule, ruleColor, scale, origin} = data;
    if (!(rule ?? options.rule)) {
      // rule false 时不绘制, undefined 时看 options.rule
      return;
    }

    const span = scale * 10;

    ctx.save();

    const finalRuleColor = ruleColor || options.ruleColor;
    ctx.strokeStyle = rgba(finalRuleColor, 0.7);

    const x = origin.x + data.x;
    const y = origin.y + data.y;
    const {width, height} = this.canvas;

    // horizontal rule
    ctx.beginPath();
    ctx.lineWidth = 12;
    ctx.lineDashOffset = -x % span;
    ctx.setLineDash([1, span - 1]);
    ctx.moveTo(0, 0);
    ctx.lineTo(width, 0);
    ctx.stroke();

    // vertical rule
    ctx.beginPath();
    ctx.lineDashOffset = -y % span;
    ctx.moveTo(0, 0);
    ctx.lineTo(0, height);
    ctx.stroke();

    // the big rule
    ctx.strokeStyle = finalRuleColor;
    ctx.beginPath();
    ctx.lineWidth = 24;
    ctx.lineDashOffset = -x % (span * 10);
    ctx.setLineDash([1, span * 10 - 1]);
    ctx.moveTo(0, 0);
    ctx.lineTo(width, 0);
    ctx.stroke();

    ctx.beginPath();
    ctx.lineDashOffset = -y % (span * 10);
    ctx.moveTo(0, 0);
    ctx.lineTo(0, height);
    ctx.stroke();

    ctx.beginPath();
    ctx.fillStyle = ctx.strokeStyle;
    let text = 0 - Math.floor(x / span / 10) * 100;
    if (x < 0) {
      text -= 100;
    }
    for (let i = x % (span * 10); i < width; i += 10 * span, text += 100) {
      if (span < 3 && text % 500) {
        continue;
      }
      ctx.fillText(text.toString(), i + 4, 16);
    }

    text = 0 - Math.floor(y / span / 10) * 100;
    if (y < 0) {
      text -= 100;
    }
    for (let i = y % (span * 10); i < height; i += 10 * span, text += 100) {
      if (span < 3 && text % 500) {
        continue;
      }
      ctx.save();
      ctx.beginPath();
      ctx.translate(16, i - 4);
      ctx.rotate((270 * Math.PI) / 180);
      ctx.fillText(text.toString(), 0, 0);
      ctx.restore();
    }
    ctx.restore();
  }
}
