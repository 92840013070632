export function flowData(pen, ctx) {
  const path = !ctx ? new Path2D() : ctx;
  const { x, y, width, ex, ey } = pen.calculative.worldRect;
  const penOffsetX = pen.offsetX;
  let offsetX = width / 7;
  if (penOffsetX > 1) {
    offsetX = penOffsetX;
  } else if (penOffsetX > 0) {
    offsetX = width * penOffsetX;
  }
  path.moveTo(x + offsetX, y);
  path.lineTo(ex, y);
  path.lineTo(x + width - offsetX, ey);
  path.lineTo(x, ey);
  path.closePath();
  if (path instanceof Path2D) return path;
}