
export const EventAction = {
  Link: 0,
  SetProps: 1,
  StartAnimate: 2,
  PauseAnimate: 3,
  StopAnimate: 4,
  Function: 5,
  GlobalFn: 6,
  Emit: 7,
  StartVideo: 8,
  PauseVideo: 9,
  StopVideo: 10,
}