export function activityFinal(ctx, pen) {
  const {x, y, width, height} = pen.calculative.worldRect;
  ctx.beginPath();
  ctx.ellipse(
    x + width / 2,
    y + height / 2,
    width / 2,
    height / 2,
    0,
    0,
    Math.PI * 2
  );
  ctx.stroke();

  ctx.beginPath();
  ctx.fillStyle = ctx.strokeStyle;
  ctx.ellipse(
    x + width / 2,
    y + height / 2,
    width / 4,
    height / 4,
    0,
    0,
    Math.PI * 2
  );
  ctx.fill();
}
