import {default as mitt} from 'mitt';
import {defaultOptions} from '../options';
import {globalStore} from './global';

export const EditType = {
  Add: 0,
  Update: 1,
  Delete: 2,
}

export const createStore = () => {
  return {
    data: {
      x: 0,
      y: 0,
      scale: 1,
      pens: [],
      origin: {x: 0, y: 0},
      center: {x: 0, y: 0},
      paths: {},
    },
    histories: [],
    pens: {},
    path2dMap: new WeakMap(),
    active: [],
    animates: new Set(),
    options: {...defaultOptions},
    emitter: mitt(),
  };
};

// Return a data store, if not exists will create a store.
export const useStore = (id = 'default') => {
  if (!globalStore[id]) {
    globalStore[id] = createStore();
    globalStore[id].id = id;
  }

  return globalStore[id];
};

export const clearStore = (store) => {
  store.data = {
    x: 0,
    y: 0,
    scale: 1,
    pens: [],
    origin: {x: 0, y: 0},
    center: {x: 0, y: 0},
    paths: {},
  };
  store.pens = {};
  store.histories = [];
  store.historyIndex = null;
  store.path2dMap = new WeakMap();
  store.active = [];
  store.hover = undefined;
  store.lastHover = undefined;
  store.animates.clear();
};
