import {focus} from './focus';
import {lifeline} from './lifeline';

export function sequencePens() {
  return {
    sequenceFocus: focus,
  };
}

export function sequencePensByCtx() {
  return {
    lifeline,
  };
}
