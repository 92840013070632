import { rectangle } from '../rectangle';
import { activityFinal } from './final';
import { swimlaneH } from './swimlaneH';
import { swimlaneV } from './swimlaneV';

export function activityDiagram() {
  return {
    forkV: rectangle,
    forkH: rectangle,
    swimlaneH,
    swimlaneV,
  };
}

export function activityDiagramByCtx() {
  return {
    activityFinal,
  };
}
